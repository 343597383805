module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bergset AS","short_name":"Bergset","start_url":"/","background_color":"rgb(17, 24, 39)","theme_color":"rgb(17, 24, 39)","display":"minimal-ui","icon":"src/images/logo2/bg-logo-negative.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fac1733d9ab48d99e21ec47e65014bf6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-9SJ4EVCTV4","cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"googleTagManager":{"trackingId":"G-9SJ4EVCTV4","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

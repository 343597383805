// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-js": () => import("./../../../src/pages/case.js" /* webpackChunkName: "component---src-pages-case-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-takk-js": () => import("./../../../src/pages/takk.js" /* webpackChunkName: "component---src-pages-takk-js" */),
  "component---src-templates-case-js": () => import("./../../../src/templates/case.js" /* webpackChunkName: "component---src-templates-case-js" */)
}

